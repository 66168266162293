import style from "./viewReport.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const UpdateReport = () => {
    // Importations
    const dispatch = useDispatch()
    const { id } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue, getValues } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const allTechnicals = useSelector((state) => state.allTechnicals)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState(null)
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [actualQuote, setActualQuote] = useState({})
    const [quote, setQuote] = useState({})
    const [tecnicos, setTecnicos] = useState([])
    const [actual, setActual] = useState({})
    // Get de quote
    const getActualQuote = async (quoteId) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'get',
                url: `${url}/reports/${quoteId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    const quote = response.data.report
                    setActualQuote(quote)
                    if (quote.quoteParts) {
                        setQuoteList(quote.quoteParts)
                    }
                }
            }
        } catch (error) {
            console.error("Error al editar el reporte:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(`${urlFront}/inicio`)
                }, [3000])
                
            }
        }
    }
    useEffect(() => {
        getActualQuote(id)
    }, [dispatch])
    useEffect(() => {
        if (tecnicos.length === 0) {
            dispatch(actions.allTechnical())
            const uniqueTechnicals = Array.from(new Map(allTechnicals.map(tech => [tech.name.toLowerCase(), tech])).values());
            setTecnicos(uniqueTechnicals);
        }
    }, [dispatch, allTechnicals])
    useEffect(() => {
        // Calcula el máximo valor de 'item'
        if (quoteList !== null) {
            const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
            setCountItem(maxItem + 1);
        }
    }, [quoteList]);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
    }, [client])
    useEffect(() => {
        handleChangeTech(actualQuote.technician
            , tecnicos)
    }, [actualQuote, dispatch, tecnicos])
    // Funciones
    const getClientName = (allClients, clientId) => {
        const client = allClients.find((client) => client.id === clientId);
        return client ? client.name : 'Cliente desconocido';
    };
    const getStoreName = (allStores, storeId) => {
        const store = allStores.find((store) => store.id === storeId);
        return store ? store.name : 'Tienda desconocido';
    };
    const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : "Ciudad desconocida";
    };
    const handleChangeTech = (id, tecnicos) => {
        const tecnicoActual = tecnicos.find((tecnico) => tecnico.id === id)
        setActual(tecnicoActual)
        return true
    };
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
    };
    function calcularDuracion(timeIn, timeOut) {
        if(timeIn != undefined && timeOut != undefined) {

            function convertFormat12to24(timer) {

                const [time, modifier] = timer.split(' ')
                let [hours, minutes, seconds] = time.split(':').map(Number);
                if (modifier === 'PM' && hours !== 12) {
                    hours += 12;
                } else if (modifier === 'AM' && hours === 12) {
                    hours = 0;
                }
                seconds = seconds === undefined ? 0 : seconds
                return { hours, minutes, seconds };
            }

            // Crear objetos Date para hoy con los tiempos especificados
            const fechaBase = new Date().toISOString().substring(0, 10); // Obtiene la fecha de hoy
  
            const { hours: hoursIn, minutes: minutesIn, seconds: secondsIn } = convertFormat12to24(timeIn);
            const { hours: hoursOut, minutes: minutesOut, seconds: secondsOut } = convertFormat12to24(timeOut);
            
            let dateTimeIn = new Date(`${fechaBase}T${hoursIn.toString().padStart(2, '0')}:${minutesIn.toString().padStart(2, '0')}:${secondsIn.toString().padStart(2, '0')}`);
            let dateTimeOut = new Date(`${fechaBase}T${hoursOut.toString().padStart(2, '0')}:${minutesOut.toString().padStart(2, '0')}:${secondsOut.toString().padStart(2, '0')}`);
        
            // Calcular la diferencia en milisegundos
            let diferencia = dateTimeOut - dateTimeIn;
        
            // Si la diferencia es negativa, significa que timeOut fue antes de timeIn, entonces asumimos que timeOut es del día siguiente
            if (diferencia < 0) {
                dateTimeOut = new Date(dateTimeOut.getTime() + 86400000); // Agrega 24 horas en milisegundos
                diferencia = dateTimeOut - dateTimeIn;
            }
        
            // Convertir la diferencia de milisegundos a horas, minutos y segundos
            const horas = Math.floor(diferencia / 3600000); // 3600000 milisegundos en una hora
            const minutos = Math.floor((diferencia % 3600000) / 60000); // 60000 milisegundos en un minuto
            const segundos = Math.floor((diferencia % 60000) / 1000); // 1000 milisegundos en un segundo
        
            return `${horas}h:${minutos}min:${segundos}s`;
        } else {
            return "-"
        }
    }
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const getDeparmentsName = (allDepartments, departmentId) => {
        const department = allDepartments.find((department) => department.id === departmentId);
        return department ? department.department : actualQuote.departmentId;
    };
    const handleChangeStore = (e, allStores) => {
        const cityIdValue = e.target.value
        const storesById = allStores.filter((store) => store.cityId === parseInt(cityIdValue))
        const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
        setFilterStore(storesByClient)
    }

    useEffect(() => {
        if(Object.keys(actualQuote).length > 0) {
            setValue("rum", actualQuote?.rum)
            setValue("date", actualQuote.date?.split('T')[0])
            setValue("serviceType", actualQuote?.serviceType)
            setValue("clientId", actualQuote?.clientId)
            setValue("departmentId", actualQuote?.departmentId)
            setValue("cityId", actualQuote?.cityId)
            setValue("storeId", actualQuote?.storeId)
            setValue("technician", actualQuote?.technician)
            setValue("source", actualQuote?.received?.name)
            setValue("sourceCC", actualQuote?.received?.identification)
            setValue("cargoRecibe", actualQuote?.received?.cargo)
            setValue("sourceEmail", actualQuote?.received?.email)
            setValue("activeNumber", actualQuote?.activeNumber)
            setValue("stateMachine", actualQuote?.stateMachine)
            setValue("state", actualQuote?.state)
            setValue("suministra", actualQuote?.suministra)
            setValue("priority", actualQuote?.priority)
            setValue("observations", actualQuote?.observations)
        }
    }, [actualQuote])


    const onSubmit = async (data) => {
        data.received = {
            name: data.source,
            identification: data.sourceCC,
            email: data.sourceEmail,
            cargo: data.cargoRecibe
        }
            try {
                setFormDisabled(true);
                const config = {
                    method: 'patch',
                    url: `${url}/reports/update/${id}`,
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    data: data
                };
                const response = await axios(config)
                if (response.data) {
                    if (response.data.success === true) {
                    setConfirmRegistro(true)
                    }
                    else {
                    setConfirmRegistro(false)
                    }
                }
                setTimeout(() => {
                    setConfirmRegistro(null);
                    reset();
                    setFormDisabled(false);
                    setTimeout(() => {
                    navigate("/reports");
                    }, 100);
                }, 2000);
            } catch (error) {
            console.error("Error al actualizar el reporte:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)
        
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })
        
                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
      }

    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
                {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Reportes</h1>
                <h4 className={style.subtitulo}>Reporte # {actualQuote.id}</h4>
                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={style.divForm}>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="rum">Número RUM</label>
                            <div className={style.divInput}>
                                <Controller name="rum"
                                    control={control}
                                    defaultValue={actualQuote.rum}
                                    render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("rum"); }} />)} />
                                <div className={style.errorMenssage}>
                                    {errors.rum && <p className={style.errorText}>{errors.rum.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="date">Fecha de solicitud</label>
                            <div className={style.divInput}>
                                <Controller
                                    name="date"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                                <div className={style.errorMenssage}>
                                    {errors.date && <p className={style.errorText}>{errors.date.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Hora de entrada</label>
                            <p className={`${style.disableInputColor} ${style.input}`}> {actualQuote?.timeIn}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Hora de salida</label>
                            <p className={`${style.disableInputColor} ${style.input}`}> {actualQuote?.timeOut}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Duración del servicio</label>
                            <p className={`${style.disableInputColor} ${style.input}`}> {actualQuote && calcularDuracion(actualQuote.timeIn, actualQuote.timeOut)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="serviceType"> Tipo de servicio </label>
                            <div className={style.divInput}>
                                <Controller name="serviceType"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: 'Seleccione el tipo de servicio' }}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("serviceType"); }}>
                                            <option value="" disabled> Seleccione el tipo </option>
                                            <option value="Correctivo"> Correctivo </option>
                                            <option value="Preventivo"> Preventivo </option>
                                            <option value="Repuestos" > Instalación de repuestos </option>
                                            <option value="Stand By" > Stand By </option>
                                            <option value="Hallazgos" > Hallazgos </option>
                                            <option value="Visita técnica" > Visita técnica </option>
                                        </select>)} />
                                <div className={style.errorMenssage}>
                                    {errors.serviceType && <p className={style.errorText}>{errors.serviceType.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="clientId"> Cliente </label>
                            <div className={style.divInput}>
                                <Controller name="clientId"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                            <option value="" disabled> Seleccione el cliente </option>
                                            {allClients !== undefined ? allClients.map((client) => (
                                                <option key={client.id} value={client.id}>
                                                    {client.name}</option>)) : null}
                                        </select>)} />
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="departmentId"> Departamento </label>
                            <div className={style.divInput}>
                                <Controller name="departmentId"
                                    control={control}
                                    defaultValue={getDeparmentsName(allDepartments, actualQuote.departmentId)}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                            <option value={actualQuote.departmentId}> {getDeparmentsName(allDepartments, actualQuote.departmentId)} </option>
                                            {allDepartments !== undefined ? allDepartments.map((department) => (
                                                <option key={department.id} value={department.id}>
                                                    {department.department}</option>)) : null}
                                        </select>)} />
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="cityId"> Ciudad </label>
                            <div className={style.divInput}>
                                <Controller name="cityId"
                                    control={control}
                                    defaultValue={getCityName(allCitys, actualQuote.cityId)}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores); }}>
                                            <option value={actualQuote.cityId}> {getCityName(allCitys, actualQuote.cityId)} </option>
                                            {filterCity.map((city) => (
                                                <option key={city.id} value={city.id}>
                                                    {city.city}</option>))}
                                        </select>)} />
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="storeId"> Almacen </label>
                            <div className={style.divInput}>
                                <Controller name="storeId"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId"); }}>
                                            <option value={actualQuote.storeId}> {getStoreName(allStores, actualQuote.storeId)} </option>
                                            {filterStore.map((store) => (
                                                <option key={store.id} value={store.id}>
                                                    {store.name}</option>))}
                                        </select>)} />
                            </div>
                        </div>  
                    </div>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="source">Quien recibe</label>
                            <div className={style.divInput}>
                                <Controller name="source"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("source"); }} />)} />
                                <div className={style.errorMenssage}>
                                    {errors.source && <p className={style.errorText}>{errors.source.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="sourceCC">C.C.</label>
                            <div className={style.divInput}>
                                <Controller name="sourceCC"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("sourceCC"); }} />)} />
                                <div className={style.errorMenssage}>
                                    {errors.sourceCC && <p className={style.errorText}>{errors.sourceCC.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <Controller
                                name="cargoRecibe"
                                control={control}
                                defaultValue={""}
                                rules={{ required: 'Este campo es obligatorio' }}
                                render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("state") }}>
                                        <option value="" disabled> Seleccione una opción </option>
                                        <option value="Líder"> Líder </option>
                                        <option value="Supervisor"> Supervisor </option>
                                        <option value="Técnico de mantenimiento"> Técnico de mantenimiento </option>
                                        <option value="Jefe de seguridad"> Jefe de seguridad </option>
                                        <option value="Gerente"> Gerente </option>
                                    </select>)} />
                            <div className={style.errorMenssage}>
                                {errors.cargoRecibe && <p className={style.errorText}>{errors.cargoRecibe.message}</p>}
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="sourceEmail">Correo electrónico</label>
                            <div className={style.divInput}>
                                <Controller name="sourceEmail"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("sourceEmail"); }} />)} />
                                <div className={style.errorMenssage}>
                                    {errors.sourceEmail && <p className={style.errorText}>{errors.sourceEmail.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="activeNumber">Numero de activo</label>
                            <div className={style.divInput}>
                                <Controller name="activeNumber"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("activeNumber"); }} />)} />
                                <div className={style.errorMenssage}>
                                    {errors.activeNumber && <p className={style.errorText}>{errors.activeNumber.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="stateMachine">¿El equipo queda operando?</label>
                            <div className={style.divInput}>
                                <Controller
                                    name="stateMachine"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("stateMachine") }}>
                                            <option value="" disabled> Seleccione una opción </option>
                                            <option value={true}> Sí </option>
                                            <option value={false}> No </option>
                                        </select>)} />
                                <div className={style.errorMenssage}>
                                    {errors.stateMachine && <p className={style.errorText}>{errors.stateMachine.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="state"> Estado del servicio</label>
                            <div className={style.divInput}>
                                <Controller name="state"
                                    control={control}
                                    defaultValue={actualQuote.state === true ? ("Abierto") : ("Cerrado")}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("state"); }}>
                                            <option value="" disabled> Seleccione el estado </option>
                                            <option value={true}> Activo </option>
                                            <option value={false}> Cerrado </option>
                                        </select>)} />
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="technician"> Técnico </label>
                            <div className={style.divInput}>
                                <Controller name="technician"
                                    control={control}
                                    defaultValue={actualQuote.technician}
                                    render={({ field }) => (
                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("technician") }}>
                                            <option value="" disabled> Seleccione el técnico</option>
                                            {tecnicos !== undefined ? tecnicos.map((client) => (
                                                client.authorized &&
                                                <option key={client.id} value={client.id}>
                                                    {client.name + " " + client.surname}</option>)) : null}
                                        </select>)} />
                            </div>
                        </div>
                        <div className={style.divCampo}>
                            {actualQuote.suministra === "" ? 
                                <>
                                    <label className={style.label}>Suministra repuestos </label>
                                    <p className={style.input}>{actualQuote.suministra === "" ? "No" : actualQuote.suministra}</p>    
                                </> :
                                <>
                                    <label className={style.label} htmlFor="suministra">¿Quién suministra repuestos?</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="suministra"
                                            control={control}
                                            defaultValue={actualQuote?.suministra}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("suministra") }}>
                                                    <option value={actualQuote?.suministra}> {actualQuote?.suministra} </option>
                                                    <option value="Almacen/Cliente"> Almacen/Cliente </option>
                                                    <option value="RefriActive ByG"> RefriActive ByG </option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.suministra && <p className={style.errorText}>{errors.suministra.message}</p>}
                                        </div>
                                    </div>
                                </>
                            }  
                        </div>
                        <div className={style.divCampo}>
                            { actualQuote.priority === "" ?
                                <>
                                    <label className={style.label}>Prioridad de repuestos </label>
                                    <p className={style.input}>{actualQuote.priority === "" ? "No" : actualQuote.priority}</p>
                                </> :
                                <>
                                    <label className={style.label} htmlFor="priority">Prioridad en repuestos</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="priority"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("priority") }}>
                                                    <option value="" disabled> Seleccione una opción </option>
                                                    <option value="Alta"> Alta </option>
                                                    <option value="Media"> Media </option>
                                                    <option value="Baja"> Baja </option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.priority && <p className={style.errorText}>{errors.priority.message}</p>}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={style.divCampo}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Observaciones</label>
                            <div className={style.divInput}>
                                <Controller name="observations"
                                    control={control}
                                    defaultValue={actualQuote.observations}
                                    render={({ field }) => (
                                        <textarea className={`${style.textareaUpdateReport} ${style.input}`} rows={10} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("observations"); }} />)} />
                                <div className={style.errorMenssage}>
                                    {errors.observations && <p className={style.errorText}>{errors.observations.message}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.divDatos}>
                        <h3 className={style.subtitulo}>Repuestos de reporte</h3>
                        <div className={style.divTabla}>
                            <table className={style.table}>
                                <tr className={style.tr}>
                                    <th className={style.th}>Item</th>
                                    <th className={style.th}>Descripción</th>
                                    <th className={style.th}>Cantidad</th>
                                    <th className={style.thres}>Descripción</th>
                                    <th className={style.thres}>Cantidad</th>
                                </tr>
                                {quoteList !== null && quoteList.length >= 1 && quoteList.map((orden) => (
                                    <tr className={style.tr} key={orden.item}>
                                        <td className={style.td}>{orden.item}</td>
                                        <td className={style.td}>{orden.description}</td>
                                        <td className={style.td}>{orden.quantity}</td>
                                        <td className={style.tdres}>{orden.description}</td>
                                        <td className={style.tdres}>{orden.quantity}</td>
                                    </tr>))}
                            </table>
                        </div>
                    </div>
                    <button type="submit" className={style.pdfbutton} >Guardar cambios</button>
                    {confirmRegistro === true ? (<><p className={style.positivo}>Reporte actualizada con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                    <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
                    <div className={style.divCampo}>
                        <label className={style.label}>Evidencias fotográficas</label>
                        <div className={style.divImag}>
                            {actualQuote.collage && Object.values(actualQuote.collage).map((imageUrl, index) => (
                                <img
                                    key={index}
                                    src={imageUrl}
                                    alt={`Evidencia fotográfica ${index + 1}`}
                                    className={style.imagenEvidencia}
                                />
                            ))}
                        </div>
                    </div>
                 </form>
            </div>
        </div>
    )

}
export default UpdateReport
